import React from "react"
import { Link, graphql } from "gatsby"
import { jsx, css } from "@emotion/core"
import { MDXRenderer } from "gatsby-plugin-mdx"

import "./reset.css"
import "typeface-ramabhadra"
import "typeface-lora"

import Image from "../components/image"
import SEO from "../components/seo"
import Header from "../components/header"
import Bio from "../components/bio"
import Footer from "../components/footer"

class IndexPage extends React.Component {
  state = {
    visible: "",
    mobileBio: false,
    positions: [],
  }

  handleScroll = initial => {
    const positions = []
    this.props.data.allMdx.edges.forEach(({ node }) => {
      const title = node.frontmatter.title
      if (this[title]) {
        const uniqueId = title + node.frontmatter.date
        const measurements = this[title].getBoundingClientRect()
        const position = measurements.top + window.scrollY - 100
        positions.push({ position, uniqueId })
        if (measurements.top > 100 && measurements.top < 150) {
          if (this.state.visible !== uniqueId) {
            this.setState({ visible: uniqueId })
          }
        }
      }
    })
    if (initial) {
      this.setState({ positions })
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
    this.handleScroll("initial")
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  render() {
    const { data } = this.props
    const posts = data.allMdx.edges
    return (
      <div>
        <SEO title="Just Some Notes" />
        <Header />

        <div
          css={css`
            display: flex;
            justify-content: space-around;
            align-items: flex-start;
            color: black;
            width: 1200px;
            margin: 0 auto;
            @media (max-width: 1380px) {
              width: 800px;
            }
            @media (max-width: 950px) {
              width: 100%;
              padding-left: 25px;
              padding-right: 25px;
              box-sizing: border-box;
            }
          `}
        >
          <div
            css={css`
              margin-top: 175px;
              width: calc(100% - 550px);
              margin-right: 100px;
              @media (max-width: 1380px) {
                margin-right: 100px;
                width: calc(100% - 350px);
              }
              @media (max-width: 850px) {
                width: 100%;
                margin-right: 0;
                margin-top: 125px;
              }
            `}
          >
            {posts.map(({ node }) => {
              const title = node.frontmatter.title || node.fields.slug
              const type = node.frontmatter.type
              const link = node.fields.slug
              const uniqueId = title + node.frontmatter.date

              if (type === "music" || type === "images") {
                return (
                  <div
                    css={css`
                      float: left;
                      width: 100%;
                      color: black;
                      margin-bottom: 50px;
                      text-align: center;
                      @media (max-width: 850px) {
                        margin-bottom: 50px;
                      }
                      p {
                        display: ${type === "music" ? "none" : ""};
                      }
                    `}
                    key={uniqueId}
                  >
                    {type === "images" && <h2>{title}</h2>}
                    <MDXRenderer>{node.body}</MDXRenderer>
                  </div>
                )
              } else {
                return (
                  <div
                    css={css`
                      float: left;
                      width: 100%;
                      color: black;
                      margin-bottom: 50px;
                      @media (max-width: 850px) {
                        margin-bottom: 50px;
                      }
                    `}
                    key={uniqueId}
                    ref={ref => (this[title] = ref)}
                  >
                    <Link to={link}>
                      <h2
                        css={css`
                          font-family: "Lora";
                          font-size: 30px;
                          font-weight: normal;
                          line-height: 30px;
                          padding: 0;
                          margin: 0;
                          color: black;
                          text-decoration: none;
                        `}
                      >
                        {title}
                      </h2>
                    </Link>

                    <p
                      css={css`
                        font-family: "Lora";
                        font-size: 16px;
                        padding-left: 25px;
                      `}
                      dangerouslySetInnerHTML={{ __html: node.excerpt }}
                    />
                  </div>
                )
              }
            })}
            <div
              css={css`
                float: left;
                width: 100%;
                margin-top: 50px;
                margin-bottom: 50px;
                text-align: center;
                @media (max-width: 850px) {
                  margin-bottom: 25px;
                  margin-top: 0;
                }
              `}
            >
              <Link to="/archive">
                <button
                  css={css`
                    border: 0;
                    background: black;
                    color: white;
                    font-family: "Ramabhadra";
                    font-size: 15px;
                    padding: 5px 10px;
                    border-radius: 5px;
                  `}
                >
                  Archive
                </button>
              </Link>
            </div>
          </div>
          <div
            css={css`
              position: -webkit-sticky;
              position: sticky;
              top: 175px;
              width: 450px;
              @media (max-width: 1380px) {
                width: 250px;
              }
              @media (max-width: 850px) {
                top: 75px;
                position: fixed;
                width: 100%;
                background: black;
                text-align: center;
                padding-bottom: 50px;
                display: ${this.state.mobileBio ? "block" : "none"};
              }
            `}
          >
            <ul
              css={css`
                margin: 0;
                padding: 0;
                @media (max-width: 850px) {
                  display: none;
                }
              `}
            >
              {posts
                .filter(({ node }) => node.frontmatter.type === "text")
                .map(({ node }) => {
                  const title = node.frontmatter.title || node.fields.slug
                  const uniqueId = title + node.frontmatter.date
                  const inView = this.state.visible === uniqueId
                  const post = this.state.positions.filter(
                    item => item.uniqueId === uniqueId
                  )[0]
                  const position = post ? post.position : ""
                  return (
                    <li
                      css={css`
                        line-height: 30px;
                        font-family: "Ramabhadra";
                        font-size: 16px;
                        font-weight: ${inView ? "bold" : "normal"};
                        text-decoration: ${inView ? "underline" : "default"};
                        cursor: pointer;
                      `}
                      key={node.fields.slug}
                      onClick={() => {
                        if (position) {
                          window.scrollTo(0, position)
                        }
                      }}
                    >
                      {title}
                    </li>
                  )
                })}
            </ul>
            <div
              css={css`
                width: 100%;
                margin-top: 50px;
                font-family: monospace;
                font-size: 14px;
                color: black;
              `}
            >
              <Bio />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt(pruneLength: 340)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            type
          }
          body
        }
      }
    }
  }
`
