import React from "react"

export default function Bio() {
  return (
    <span>
      Welcome to my personal blog. Here I write about games, programming, art,
      music and anything else that can be captured in words.
      <br />
      <br />
      If you're into RSS feeds, you can follow the one of this site over{" "}
      <a href={"https://justsomenotes.com/rss.xml"}>here</a>.
    </span>
  )
}
