import React from "react"
import { jsx, css } from "@emotion/core"
import "typeface-lora"

export default function Footer() {
  return (
    <div
      css={css`
        float: left;
        width: 100%;
        height: 50px;
        color: #eeeeee;
        text-align: center;
        font-family: "Lora";
        font-size: 10px;
        line-height: 50px;
      `}
    >
      copyright 2020
    </div>
  )
}
